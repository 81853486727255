import { TranslationKey } from 'generated/util/TranslationKey'
import { string } from 'yup'

export interface Translation {
    /**
     * The translations required for each language.
     */
    translation: {
        errors: {
            general: string,
            404: string,
            noData: string
        },
        reauthenticate: {
            modalTitle: string,
            success: string,
            info: string
        },
        logoff: {
            message: string
        }
        buttons: {
        },
        common: {
            name: string,
            system: string,
            type: string,
            subtype: string,
            room: string,
            start: string,
            stop: string,
            starting: string,
            stopping: string,
            status: string,
            temperature: string,
            temp: string,
            volts: string
            stopAll: string,
            top: string,
            bottom: string,
            on: string,
            off: string,
            cancel: string,
            clear: string,
            never: string,
            actions: {
                label: string
                save: string
                create: string
                delete: string
                cancel: string
                assign: string
                add: string
                submit: string
                reset: string
                ok: string
                forcePassword: string
                addAnother: string
                upload: string
                uploadAnother: string
                login: string
                saveAndContinue: string
                saveAndExit: string
                view: string
                edit: string
                clone: string
                next: string
                back: string
                return: string
                lock: string
                unlock: string
                changePassword: string
                generatePassword: string
                pair: string
                keypair: string
                forgotPassword: string
                resetPasswordLink: string
                confirm_account: string
                user_confirmed: string
                impersonate: string
                hierarchy: string
                duplicate: string
                assignment: string
                transfer: string
            },
            notifications: {
                delete: string
                edit: string
                create: string
                clone: string
                transfer: string
            },
            message: {
                noRecord: string
            }
        },
        contact: { [key in string]: string },
        dropDowns: {
            moduleHeader: {
                close: string,
                collapse: string,
                addToDashboard: string,
                graphView: string,
                expand: string,
                lock: string,
                settings: string
                sliderType: string,
            }
        },
        forms: {
            hidden: string,
            header: {
                label: { list: string }
            },
            permissions: {
                field: {
                    resource: { label: string, validation: { required: string } },
                    action: { label: string, validation: { required: string } },
                    description: { label: string, validation: { required: string } },
                },
            },
            role: {
                modal: {
                    confirm: { delete: { message: string, title: string } },
                    view: { header: { roles: string, modules: string, actions: string, users: string } }
                },
                title: string,
                field: {
                    name: { label: string, validation: { required: string } },
                    level: { label: string, validation: { required: string, optional: string }, notes: string },
                    description: { label: string, validation: { required: string } },
                    permissions: { label: string, validation: { required: string }, selectedInfo: string },
                    active: { label: string, validation: { required: string } },
                    organizationId: { label: string, validation: { required: string } },
                    userId: { label: string, validation: { required: string }, dropdownLabel: string },
                    roles: { label: string, validation: { required: string } },
                },
                name: {
                    mechanics: string,
                    drivers: string,
                    operators: string,
                    administrators: string,
                    viewer: string,
                    owner: string
                }
            },
            group: {
                title: string,
                search: string,
                modal: { confirm: { delete: { message: string, title: string } } },
                basicInfo: string,
                field: {
                    name: { label: string, validation: { required: string } },
                    roles: { label: string, validation: {} },
                    parentId: { label: string, dropdownLabel: string, validation: {} },
                    users: { label: string, validation: {} },
                    ntc_assets: { label: string, validation: {} },
                    children: { label: string, validation: {} },
                },
                name: {
                    roles: string,
                    users: string
                }
            },
            user: {
                title: string,
                modal: {
                    confirm: {
                        delete: { message: string, title: string },
                        lock: { message: string, title: string },
                        unlock: { message: string, title: string }
                        confirm_account: { message: string, title: string },
                        impersonate: { message: string, title: string },
                    }
                },
                preferences: {
                    weather: string,
                    news: string,
                },
                notifications: {
                    lock: string,
                    unlock: string,
                    delete: string
                    edit: string
                    create: string
                    roleUpdate: string,
                    confirm_account: string
                    impersonate: string,
                    impersonating: string
                },
                field: {
                    firstName: { label: string, validation: { required: string } },
                    lastName: { label: string, validation: { required: string } },
                    name: { label: string, validation: { required: string } },
                    email: { label: string, validation: { required: string, email: string }, accountConfirmed: string },
                    username: { label: string, validation: { required: string, min: string, max: string } },
                    phone: { label: string, validation: { required: string } },
                    existingPassword: { label: string, required: string }
                    password: {
                        sentResetLink: string,
                        sentResetEmailInfo: string,
                        resourceNotFound: string,
                        updateMessage: string,
                        updateMessageInfo: string,
                        failedMessage: string,
                        security: string,
                        stig: string,
                        passwordManagement: string,
                        label: string,
                        labelNew: string,
                        accountLocked: string,
                        tooManyLoginAttempts: string,
                        forcePasswordTitle: string,
                        validation: {
                            required: string,
                            invalid: string,
                            min: string,
                            stigMin: string,
                            max: string,
                            upperCase: string,
                            lowerCase: string,
                            specialChar: string,
                            number: string
                            stigPatternMessage: string,
                            nonStigPatternMessage: string,
                            passwordReused: string,
                            password8CharsMustChange: string,
                            passwordTooNew: string,
                            passwordConfirmationMissing: string,
                            passwordConfirmationNoMatch: string,
                            existingPasswordNoMatch: string
                        }
                    },
                    confirmPassword: { label: string, validation: { required: string, match: string } },
                    roles: { label: string, validation: { required: string } },
                    groups: { label: string, validation: { required: string } },
                    active: { label: string, validation: { required: string } },
                    company: { label: string, validation: { required: string } },
                    weather_country: { label: string, validation: { required: string }, helpText: string },
                    weather_city: { label: string, validation: { required: string }, helpText: string },
                    weather_state: { label: string, validation: { required: string }, helpText: string },
                    news_country: { label: string, validation: { required: string }, helpText: string, dropdownLabel: string },
                    news_city: { label: string, validation: { required: string }, helpText: string },
                    news_state: { label: string, validation: { required: string }, helpText: string },
                    news_keywords: { label: string, validation: { required: string }, helpText: string }
                }
            },
            organization: {
                title: string,
                name: string,
                search: string,
                modal: {
                    confirm: { delete: { message: string, title: string } },
                    association: { user: { title: string, label: string }, asset: { title: string, label: string } }
                },
                field: {
                    name: { label: string, validation: { required: string } },
                    typeName: { label: string, validation: {} },
                    users: { label: string, validation: {} },
                    assets: { label: string, validation: {} },
                },
                buttons: {
                    linkUnlinkUser: string
                }
            },
            asset: {
                title: string,
                search: string,
                warning: string,
                modal: {
                    confirm: { delete: { message: string, title: string } }
                },
                field: {
                    name: { label: string, validation: { required: string } },
                    description: { label: string, validation: {} },
                    connected: { label: string, validation: {} },
                    last_online: { label: string, validation: {} },
                    status: { label: string, validation: {} },
                    remote_control: { label: string, validation: {} },
                    allow_remote_control: { label: string, validation: {} },
                    key: { label: string },
                    secret: { label: string },
                    hardware_key: { label: string },
                    project_id: { label: string },
                    ssh_tunnel_port: { label: string },
                    show_starlink: { label: string },
                    serial_number: { label: string },
                    asset_id: { label: string },
                    business_unit: { label: string },
                    unit_number: { label: string },
                    customer_number: { label: string },
                    transfer_org: { label: string, dropdownLabel: string, validation: { required: string } },
                    users: { label: string },
                    groups: { label: string },
                    show_restrict: { label: string },
                }
            },
            training: {
                title: string,
                modal: {
                    confirm: {
                        delete: { message: string, title: string }
                    }
                },
                field: {
                    name: { label: string, validation: { required: string } },
                    description: { label: string, validation: {} },
                    repeat_count: { label: string, validation: { required: string, min: string, max: string } },
                    source_id: { label: string, validation: { required: string }, dropdownLabel: string },
                }
            },
        },
        lang: {
            en: {
                us: string
            },
            es: {
                mx: string
            }
        },
        menu: {
            overview: string,
            groups: string,
            organizations: string
            ntc_assets: string,
            users: string,
            roles: string,
            settings: string
            profileSettings: string,
            themeUpdated: string,
            languageUpdated: string,
            darkMode: string,
            language: string,
            login: string,
            logout: string,
            lastLogin: string,
            firstLoginMessage: string,
            trainings: string,
        },
        modules: {
            dashboard: {
                name: string,
                roles: string,
                active: string,
                modal: { confirm: { delete: { message: string } } },
                button: { saveLayout: string },
                empty: string
            },
            map: {
                type: {
                    assetTracker: string
                },
                layer: {
                    location: string,
                    route: string
                },
                filter: { startDate: string, endDate: string, search: string, allAsset: string },
                marker: {
                    connected: string,
                    notConnected: string,
                    stationary: string,
                    lastOnline: string,
                    latitude: string,
                    longitude: string,
                }
            },
            news: {
                name: string,
                showLess: string,
                showMore: string,
            },
            weather: {
                name: string,
                search: string,
                current: {
                    header: string,
                    today: string
                },
                aircondition: {
                    header: string,
                    realfeel: string,
                    wind: string,
                    clouds: string,
                    humidity: string
                }
            }
        },
        time: {
            day: string;
            hour: string;
            minute: string;
            second: string;
            week: string;
            ago: string;
        }
    }
}

/**
 * Responsible for taking in a Key object and returning a formatted string.
 * @param t : Translation object for i18next
 * @param key : object being translated. May have child keys to be translated.
 * @returns : formatted string like 'Awning - Curbside Front' or 'Rack Control - Room 2'
 */
export function translateKey(t, key: TranslationKey) {
    // Only translate string values.  Translating a numeric value for keys like 'count'
    // will prevent i18next features like plurals from working.
    if (!key.params) return typeof key.value == 'string' ? t(key.value) : key.value

    const options = {}
    Object.entries(key.params).forEach(([k, v]) => options[k] = translateKey(t, v))

    return t(key.value, options)
}
