import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Box, Grid, useTheme } from '@mui/material'

import { ClientContext, PageContext } from 'Context'
import { ASSET, CREATE, isAuthorized } from 'generated/auth/Permissions'

import { FormMode } from 'components/inputs/forms/Forms'
import FormTextControl from 'components/inputs/forms/controls/FormTextControl'
import NTCButton from 'components/inputs/buttons/NTCButton'
import BaseModalDialog from 'components/display/modals/BaseModalDialog'

import { AssetApiService } from 'utils/HTTP/AssetUtils'
import UserApiService from 'utils/UserHttpUtils'
import GroupApiService from 'utils/HTTP/GroupUtils'
import { handleException } from 'utils/HTTPUtils'
import FormCheckBoxControl from 'components/inputs/forms/controls/FormCheckboxControl'
import { FormMultiSelectControl } from 'components/inputs/forms/controls/FormMultiSelectControl'

function AssetFormButton({ onClick, text, backgroundColor = null }) {
    return (
        <Grid item xs={12} sm={3} md={2}>
            <NTCButton onClick={onClick} text={text} backgroundColor={backgroundColor} />
        </Grid>
    )
}

function ActionButtons({ onCancel, onSave, t, theme }) {
    return (
        <Grid container spacing={3} justifyContent={'right'}>
            <AssetFormButton onClick={onCancel} text={t('common.actions.cancel')} />
            <AssetFormButton onClick={onSave} text={t('common.actions.save')}
                backgroundColor={theme.palette.custom.nomadRed} />
        </Grid>
    )
}

// Function to filter IDs by selected options
function filterIdsByOptions(options, dataItems) {
    return options?.filter(option => {
        return dataItems?.includes(option.value)
    }) || []
}

function prepareItemList(options) {
    return options?.map(({ id, name }) => ({ value: id, label: name }))
}

export const AssetForm = ({
    organizationId = '',
    assetId,
    mode,
    showResponseStatus,
    handleCloseModal,
    actionCallback = (v) => { },
    page = 'asset'
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { client, setClient } = useContext(ClientContext)
    const { setLoadingPanel } = useContext(PageContext)
    const isOrg = page === 'organization'

    const fields = [
        { id: 'id', name: 'id', required: false, visible: false },
        { id: 'name', name: 'name', required: true, visible: true },
        { id: 'description', name: 'description', required: false, visible: true },
        { id: 'project_id', name: 'project_id', required: false, visible: isAuthorized(ASSET, CREATE) },
        { id: 'ssh_tunnel_port', name: 'ssh_tunnel_port', required: false, visible: isAuthorized(ASSET, CREATE) },
        { id: 'key', name: 'key', required: false, visible: isAuthorized(ASSET, CREATE) },
        { id: 'secret', name: 'secret', required: false, visible: isAuthorized(ASSET, CREATE), placeholder: 'forms.hidden', hideValue: true },
        { id: 'hardware_key', name: 'hardware_key', required: false, visible: isAuthorized(ASSET, CREATE), placeholder: 'forms.hidden', hideValue: true },
    ]

    const starlinkfields = [
        { id: 'serial_number', name: 'serial_number', required: false, visible: true },
        { id: 'asset_id', name: 'asset_id', required: false, visible: true },
        { id: 'business_unit', name: 'business_unit', required: false, visible: true },
        { id: 'unit_number', name: 'unit_number', required: false, visible: true },
        { id: 'customer_number', name: 'customer_number', required: false, visible: true },

    ]

    function getDefaultValues(fields: any[]) {
        return fields.reduce((values, field) => {
            values[field.name] = ''
            return values
        }, {})
    }

    const defaultValues = getDefaultValues(fields)

    const [asset, setAsset] = useState(defaultValues)
    const [openFormDialog, setOpenFormDialog] = useState(true)
    const [userDropDownList, setUserDropDownList] = useState([])
    const [groupDropDownList, setGroupDropDownList] = useState([])

    function createValidationSchema(fields: any[]) {
        const validationSchemaFields = {
            id: Yup.string(),
            name: Yup.string().required(t('forms.asset.field.name.validation.required'))
        }

        fields.forEach((field) => {
            validationSchemaFields[field['name']] = field.required ?
                Yup.string().required(t(`forms.asset.field.${field.name}.validation.required`)) :
                Yup.string().optional()
        })

        return Yup.object().shape(validationSchemaFields)
    }
    
    const validationSchema = createValidationSchema(fields)

    const { register, control, handleSubmit, setValue, watch, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: asset,
        mode: 'all'
    })

    useEffect(() => {
        const getUsersAndGroups = async () => {
            try {
                const [users, groups] = await Promise.all([
                    UserApiService.fetchAllUsers(),
                    GroupApiService.fetchAllGroups(),
                ])
                const usersDropDownOptions = prepareItemList(users)
                const groupsDropDownOptions = prepareItemList(groups)
                setUserDropDownList(usersDropDownOptions)
                setGroupDropDownList(groupsDropDownOptions)
            } catch (ex) {
                showResponseStatus({ success: false, entity: 'Asset', message: ex.message })
            }
        }
        getUsersAndGroups();

        if (!assetId) {
            trigger()
            return
        }

        async function fetchData() {
            setLoadingPanel(true)
            try {
                const assetData = await AssetApiService.getAssetById(assetId)

                if (mode === 'edit') {
                    setValue('id', assetId)
                }

                fields.forEach(field => {
                    setValue(field.name, field.hideValue ? '' : assetData[field.name] || '')
                })

                setValue('show_starlink', assetData['show_starlink'])
                starlinkfields.forEach(field => {
                    setValue(field.name, assetData[field.name] || '')
                })
                    
                setAsset(assetData)

                setValue('show_restrict', assetData['show_restrict'])

                const selecteUsersdDropDownList = filterIdsByOptions(userDropDownList, assetData.authorized_users)
                const selectedGroupsDropDownList = filterIdsByOptions(groupDropDownList, assetData.authorized_groups)
                
                setValue('authorized_users', selecteUsersdDropDownList)
                setValue('authorized_groups', selectedGroupsDropDownList)

            } catch (ex) {
                showResponseStatus({ success: false, entity: 'Asset', message: ex.message })
            } finally {
                setLoadingPanel(false)
            }
        }

        fetchData()
    }, [assetId, mode])

    const onFormSubmit = async (data: any) => {
        setLoadingPanel(true)
        
        data.org_id = organizationId || client['org']?.id
        if (mode !== FormMode.EDIT) {
            delete data.id
        }
        
        try {
            const apiFunction = mode === FormMode.CREATE ? AssetApiService.createAsset : AssetApiService.updateAsset
            
            data.authorized_users = data.authorized_users?.map(user => user.value) || []
            data.authorized_groups = data.authorized_groups?.map(group => group.value) || []

            if (!data.authorized_users.length && !data.authorized_groups.length) data.show_restrict = false;
            
            const res = await apiFunction(data)
            setAsset(res)

            showResponseStatus({
                success: true,
                entity: 'Asset',
                message: t(`common.notifications.${mode}`, { name: t('forms.asset.title') })
            })

            if (isOrg) {
                actionCallback({ id: assetId || res?.id })
            } else {
                setOpenFormDialog(false)
            }
        } catch (ex) {
            const errorMessage = await handleException(ex)
            showResponseStatus({ success: false, entity: 'Asset', message: errorMessage })
        } finally {
            handleCloseModal()
            setLoadingPanel(false)
        }
    }

    const getFormHeader = () => {
        return t(`common.actions.${mode}`) + ' ' + t(`forms.asset.title`)
    }

    function handleClose() {
        setOpenFormDialog(false)
        handleCloseModal()
    }
    const showStarlinkFields = watch('show_starlink', !!asset['show_starlink'])
    const showRestrictedFields = watch('show_restrict', !!asset['show_restrict'])

    return <BaseModalDialog
        modalTitle={getFormHeader()}
        maxWidth="lg"
        open={openFormDialog}
        handleClose={handleClose}
    >
        <Box px={3} py={2}>
            <Grid container spacing={3}>
                <input type="hidden" id="id" name="id" value={asset.id} {...register('id')} />

                {fields.map(field => (
                    field.visible && (
                        <Grid item xs={12} sm={12} key={field.id}>
                            <FormTextControl
                                id={field.id}
                                name={field.name}
                                defaultValue={asset[field.name]}
                                label={t(`forms.asset.field.${field.name}.label`)}
                                required={field.required}
                                placeholder={field.placeholder ? t(field.placeholder) : undefined}
                                {...{ register, errors }}
                            />
                        </Grid>
                    )
                ))}
                {/* Starlink field checkbox */}
                <Grid item xs={12} sm={12} key={'show_starlink'}>
                    <FormCheckBoxControl
                        id={'show_starlink'}
                        name={'show_starlink'}
                        defaultValue={!!asset['show_starlink']}
                        label={t(`forms.asset.field.show_starlink.label`)}
                        {...{ control, errors }}
                    />
                </Grid>

                {/* Starlink fields */}
                {showStarlinkFields ? starlinkfields.map(field => (
                    field.visible && (
                        <Grid item xs={12} sm={12} key={field.id}>
                            <FormTextControl
                                id={field.id}
                                name={field.name}
                                defaultValue={asset[field.name]}
                                label={t(`forms.asset.field.${field.name}.label`)}
                                required={field.required}
                                {...{ register, errors }}
                            />
                        </Grid>
                    )
                )) : <></>}

                {/* Limit field field checkbox */}
                <Grid item xs={12} sm={12} key={'show_restrict'}>
                    <FormCheckBoxControl
                        id={'show_restrict'}
                        name={'show_restrict'}
                        defaultValue={!!asset['show_restrict']}
                        label={t(`forms.asset.field.show_restrict.label`)}
                        {...{ control, errors }}
                    />
                </Grid>
                {showRestrictedFields && 
                    <>
                        <Grid item xs={12} sm={12}>
                            <FormMultiSelectControl name={'authorized_users'} label={t('forms.asset.field.users.label')}
                                options={userDropDownList || []}{...{ control, errors }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormMultiSelectControl name={'authorized_groups'} label={t('forms.asset.field.groups.label')}
                                options={groupDropDownList || []}{...{ control, errors }}
                            />
                        </Grid>
                    </>
                }
            </Grid>
            <Box sx={{ mt: 2 }}>
                <ActionButtons onCancel={handleClose} t={t} theme={theme} onSave={handleSubmit(onFormSubmit)} />
            </Box>
        </Box>
    </BaseModalDialog>
}
